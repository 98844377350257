body {
  margin: 0;
}

.ol-control button {
  background-color: white;
  height: 40px;
  width: 40px;
  margin: 10px 5px 0px 10px;
  border-radius: 30px;
  outline: none;
  background-color: #005847;
  color: #fbf579
}

button[title="Attributions"] {
  display: none
}

.ol-attribution ul {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  position: absolute;
  bottom: 0px;
}
